<template>
  <v-container fill-height>
    <v-row justify="center" align="center">
      <v-col xs12 sm8 md4>
        <v-card class="elevation-12">
          <v-toolbar dark color="primary">
            <v-toolbar-title>เข้าสู่ระบบ PCoC</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-text-field
                name="login"
                label="รหัสรพ.สต. 13 หลัก"
                type="text"
                v-model="hosp_code"
              ></v-text-field>
              <v-text-field
                id="password"
                name="password"
                label="รหัสผ่าน"
                type="password"
                autocomplete="current_password"
                v-model="hosp_pass"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn block color="primary" @click="signInButtonHandler"
              >เข้าสู่ระบบ</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";

const LOGIN_PATH = "http://164.115.22.214/api/pcoc/login";

export default {
  name: "Login",

  props: {},

  components: {},

  data: () => {
    return {
      hosp_code: "",
      hosp_pass: "",
    };
  },

  methods: {
    applyRouterLink(name) {
      this.$router.push({ name });
    },
    signInButtonHandler() {
      console.log("Login pressed");
      const params = {
        hosp_code: this.hosp_code,
        hosp_pass: this.hosp_pass,
      };
      axios
        .post(LOGIN_PATH, params)
        .then((response) => {
          console.log("API_URL + LOGIN_PATH response", response.data);
          //   let resp = response.data.hosp_info
          //   console.log(r)
          // this.loading = false
          //   setup cookies
          let credential = {
            hosp_code: response.data.hosp_info.org_id,
            hosp_name: response.data.hosp_info.org_name,
          };
          this.$cookies.set("user", credential);
          window.location.reload();
        })
        .catch((error) => {
          console.error("LOGIN_PATH error", error);
          this.$store.commit("setAlert", {
            show: true,
            text: "ชื่อผู้ใช้งาน หรือ รหัสผ่านไม่ถูกต้อง",
            type: "error",
          });
        });
    },

    //   signInMUHandler() {
    //     let url = "https://idp.mahidol.ac.th/adfs/oauth2/authorize/?response_type=code&resource=85fd2fca-ba9d-4d3b-abbb-ceca4269defa&client_id=f1bc437f-0240-43e8-a7e2-c624a265e46e&redirect_uri=https%3A%2F%2Fgsisauth-graduate.mahidol.ac.th%2Fadfs2.php&response_mode=form_post&scope=allatclaims";
    //     window.open(url, "_self");
    //   },

    //   reloadWindow() {
    //     // alert("reload");
    //     // check if it has get request with url
    //     var token = this.$route.query.token;
    //     if (token) {
    //       // console.log(token)
    //       // call /auth/me to get JWT token

    //       axios
    //         .post(API_URL + "/auth/me", null, {
    //           headers: {
    //             accept: "application/json",
    //             Authorization: "Bearer " + token,
    //             "Content-Type": "application/json",
    //           },
    //         })
    //         .then((response) => {
    //           console.log("AUTH/ME response", response);
    //           // this.loading = false
    //           let decoded = jwt_decode(response.data.access_token);
    //           let permission = decoded.permission;

    //           let credential = {
    //             bearer_token: token,
    //             access_token: response.data.access_token,
    //             credential: "mu-it",
    //             permission: permission,
    //           }
    //           this.$store.commit("setUser", credential);

    //           this.$cookies.set("user", credential);

    //           // console.log(this.$routes);
    //           this.routerPush(this.$routes.DASHBOARD.name);

    //         })
    //         .catch((error) => {
    //           console.error("AUTH/ME error", error);
    //           this.$store.commit("setAlert", {
    //             show: true,
    //             text: "ชื่อผู้ใช้งาน หรือ รหัสผ่านไม่ถูกต้อง",
    //             type: "error",
    //           });
    //         });
    //     }
    //     // else console.log("nothing happened here");
    //   },
  },

  mounted() {
    // alert("reload");
    //   this.reloadWindow();
    // console.log("loaded!!");
  },
};
</script>
  
  <style></style>
  