<template>
  <v-app id="v-app">
    <template v-if="user.hosp_code">
      <v-app-bar app class="blue darken-4 no-print">
        <v-btn icon dark @click.stop="drawer = !drawer">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
        <v-toolbar-title class="white--text"
          >ระบบจัดการเวชภัณฑ์คงคลัง : {{ user.hosp_name }}</v-toolbar-title
        >
      </v-app-bar>
      <v-navigation-drawer
        v-model="drawer"
        app
        fixed
        temporary
        class="no-print"
      >
        <v-list nav dense class="no-print">
          <template v-for="menu in menuList">
            <v-list-item
              v-if="menu.handler"
              :key="menu.title"
              
              link
              @click="menu.handler"
            >
              <v-list-item-icon>
                <v-icon :color="menu.color">{{ menu.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ menu.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-else :key="menu.title" class="my-2">
              <v-list-item-content>
                <v-list-item-title>{{ menu.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-navigation-drawer>
      <v-main>
        <router-view />
      </v-main>
      <v-dialog v-model="signout" width="70%">
        <v-card class="">
          <v-card-title class="">
            ออกจากระบบ
          </v-card-title>
          <v-card-content class="ma-5">
            ต้องการออกจากระบบ PCoC
          </v-card-content>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
            @click="signout=false">
              ยกเลิก
            </v-btn>
            <v-btn
              color="red"
              class="white--text"
              @click="signoutHandler">
              ออกจากระบบ
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <template v-else>
        <div>
            <Login />
        </div>
    </template>
  </v-app>
</template>

<script>
import Login from "../screen/login.vue";
export default {
  name: "PCoC",
  created(){
    document.title = " - PCoC - ระบบจัดการคลังเวชภัณฑ์ของรพ.สต."
  },
  components:{
    Login
  },
  methods: {
    applyRouterLink(name) {
      this.$router.push({ name });
    },
    checkCookie(){
      // console.log("check cookie called")
      let cookie = this.$cookies.get("user")
      console.log(cookie)
      if(cookie){
        // set user here
        console.log("has cookie")
        this.user.hosp_code = cookie.hosp_code
        this.user.hosp_name = cookie.hosp_name
        console.log(this.user)
        this.applyRouterLink(this.$routes.INVENTORY.name)
      } else{
        console.log("No Cookie")
      }
    },
    signoutHandler(){
      this.$cookies.remove("user");
      this.signout = false;
      window.location.reload();
    }
  },
  data: function () {
    return {
      drawer: false,
      menuList: [
        {
          title: "ระบบจัดการเวชภัณฑ์คงคลัง",
          handler: null,
        },
        {
          title: this.$routes.INVENTORY.displayName,
          icon: "mdi-home-circle-outline",
          color: "green",
          handler: () => this.applyRouterLink(this.$routes.INVENTORY.name),
        },
        {
          title: this.$routes.ASN.displayName,
          icon: "mdi-package-variant-closed",
          color: "orange",
          handler: () => this.applyRouterLink(this.$routes.ASN.name),
        },
        {
          title: this.$routes.WITHDRAW.displayName,
          icon: "mdi-file-edit-outline",
          color: "blue",
          handler: () => this.applyRouterLink(this.$routes.WITHDRAW.name),
        },
        {
          title: "ออกจากระบบ",
          icon: "mdi-logout",
          color: "red",
          handler: () => this.signout = true,
        },
      ],
      // hospital: "โรงพยาบาลส่งเสริมสุขภาพตำบลโนนเจริญ",
      user: {
        hosp_code: null,
        hosp_name: null
      },
      signout: false
    };
  },
  computed: {},
  mounted() {
    this.checkCookie()
  },
};
</script>